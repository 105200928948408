import { defineStore } from "pinia";
import type { components } from "~/types/api-spec";

type TooltipLocationWithDevices = components["schemas"]["TooltipLocationWithDevices"];
type TooltipParameter = components["schemas"]["TooltipParameter"];
type TooltipDeviceFull = components["schemas"]["TooltipDeviceFull"];
type TooltipAddress = components["schemas"]["TooltipAddress"];
type TooltipCenter = components["schemas"]["TooltipCenter"];
type TooltipEvent = components["schemas"]["TooltipEvent"];
type TooltipOffer = components["schemas"]["TooltipOffer"];
type TooltipPartner = components["schemas"]["TooltipPartner"];
type TooltipInvoice = components["schemas"]["TooltipInvoice"];
type TooltipDocumentation = components["schemas"]["TooltipDocumentation"];
type PartType = components["schemas"]["PartType"];
type PartTypeCircuit = components["schemas"]["PartTypeCircuit"];
type TooltipPartFull = components["schemas"]["TooltipPartFull"];
type TooltipParameterHistory = components["schemas"]["TooltipParameterHistory"];
type TooltipBusinessCase = components["schemas"]["TooltipBusinessCase"];
type tooltipDeparture = components["schemas"]["SimpleDeparture"];

export type LocationTooltip = TooltipLocationWithDevices;
export type DeviceTooltip = TooltipDeviceFull & {parameters: TooltipParameter[]};
export type AddressTooltip = TooltipAddress;
export type CenterTooltip = TooltipCenter;
export type EventTooltip = { events: TooltipEvent[], phaseOrder: number };
export type OfferTooltip = TooltipOffer;
export type PartnerTooltip = TooltipPartner;
export type PartTypeTooltip = { part_type?: PartType, circuits?: PartTypeCircuit[] };
export type InvoiceTooltip = TooltipInvoice;
export type PartTooltip = TooltipPartFull & {parameters: TooltipParameter[]};
export type DocumentationTooltip = TooltipDocumentation;
export type ParameterTooltip = TooltipParameterHistory[];
export type BusinessCaseTooltip = TooltipBusinessCase;
export type DepartureTooltip = tooltipDeparture;

interface Stores {
  documentationStore: { [id: string]: TooltipDocumentation }
  locationStore: { [id: string]: TooltipLocationWithDevices },
  deviceStore: { [id: string]: TooltipDeviceFull },
  addressStore: { [id: string]: TooltipAddress },
  centerStore: { [id: string]: TooltipCenter },
  eventStore: { [id: string]: TooltipEvent[] },
  offerStore: { [id: string]: TooltipOffer },
  partnerStore: { [id: string]: TooltipPartner },
  partTypeStore: { [id: string]: PartTypeTooltip },
  partStore: { [id: string]: TooltipPartFull },
  invoiceStore: { [id: string]: TooltipPartFull },
  parameterStore: { [id: string]: ParameterTooltip },
  businessCaseStore: { [id: string]: TooltipBusinessCase }
}

export const useTooltipData = defineStore("tooltip_data", () => {
  const { call } = useApi();

  function notifyTooltipError () {
    useNotifier().error("tooltip");
  }

  const stores = reactive<Stores>({
    documentationStore: {},
    locationStore: {},
    deviceStore: {},
    addressStore: {},
    centerStore: {},
    eventStore: {},
    offerStore: {},
    partnerStore: {},
    partTypeStore: {},
    partStore: {},
    invoiceStore: {},
    parameterStore: {},
    businessCaseStore: {}
  });

  const loading: {[storeName: string]: boolean} = {};
  for (const key in stores) {
    loading[key] = false;
  }

  const resetStore = () => {
    Object.keys(stores).forEach((key) => {
      stores[key as keyof Stores] = {};
    });
  };

  async function getSimpleData (store: keyof Stores, path: string, id: string) {
    if (!stores[store][id] && !loading[store]) {
      loading[store] = true;
      const { data, error } = await call(path as APIPath, "get" as never, undefined, undefined);
      if (data.value) {
        stores[store][id] = data.value;
      }
      if (error.value) {
        notifyTooltipError();
      }
      loading[store] = false;
    }

    return stores[store][id];
  }

  // Location is seemingliny always together with device(s)
  const getLocationTooltipData = async (locationId: string) => {
    return await getSimpleData("locationStore",
    `/api/department/tooltip/location/${locationId}` as APIPath,
    locationId) as TooltipLocationWithDevices;
  };

  const getDeviceTooltipData = async (deviceId: string) => {
    if (!stores.deviceStore[deviceId] && !loading.deviceStore) {
      loading.deviceStore = true;
      const { data, error } = await call("/api/department/tooltip/device/{id}/", "get", { path: { id: deviceId } });
      if (data.value) {
        const deviceTooltip = {
          ...data.value,
          parameters: data.value.parts.map(part => part.parameters).flat()
        };
        stores.deviceStore[deviceId] = deviceTooltip;
      }
      if (error.value) {
        notifyTooltipError();
      }
      loading.deviceStore = false;
    }

    return {
      ...stores.deviceStore[deviceId],
      parameters: []
    } as DeviceTooltip;
  };

  const getPartTooltipData = async (partId: string) => {
    if (!stores.partStore[partId] && !loading.partStore) {
      loading.partStore = true;
      const { data, error } = await call("/api/department/tooltip/part/{id}/", "get", { path: { id: partId } });
      if (data.value) {
        const partTooltip = {
          ...data.value,
          parameters: data.value.device.parts.map(part => part.parameters).flat()
        };
        stores.partStore[partId] = partTooltip;
      }
      if (error.value) {
        notifyTooltipError();
      }
      loading.partStore = false;
    }
    return {
      ...stores.partStore[partId]
      // parameters: []
    } as PartTooltip;
  };

  const getAddressTooltipData = async (addressId: string) => {
    return await getSimpleData("addressStore",
      `/api/department/tooltip/address/${addressId}`,
      addressId) as TooltipAddress;
  };

  const getBusinessCaseTooltipData = async (businessCaseId: string) => {
    return await getSimpleData("businessCaseStore",
      `/api/department/tooltip/business-case/${businessCaseId}`,
      businessCaseId) as TooltipBusinessCase;
  };

  const getCenterTooltipData = async (centerId: string) => {
    return await getSimpleData("centerStore",
      `/api/department/tooltip/center/${centerId}`,
      centerId) as TooltipCenter;
  };

  const getEventTooltipData = async (eventId: string, phaseOrder?: number | null) => {
    const events = await getSimpleData("eventStore", `/api/department/tooltip/event/${eventId}`, eventId);
    return { events, phaseOrder } as EventTooltip;
  };

  const getDocumentationTooltipData = async (documentationId: string) => {
    const documentation = await getSimpleData("documentationStore", `/api/department/tooltip/documentation/${documentationId}`, documentationId);
    return documentation as DocumentationTooltip;
  };

  const getOfferTooltipData = async (offerId: string) => {
    return await getSimpleData("offerStore",
      `/api/department/tooltip/offer/${offerId}`,
      offerId) as TooltipOffer;
  };

  const getPartnerTooltipData = async (partnerId: string) => {
    return await getSimpleData("partnerStore",
      `/api/department/tooltip/partner/${partnerId}`,
      partnerId) as TooltipPartner;
  };

  const getPartTypeTooltipData = async (partTypeId: string) => {
    if (!stores.partTypeStore[partTypeId] || !stores.partTypeStore[partTypeId].part_type) {
      loading.partTypeStore = true;
      const { data, error } = await call("/api/department/part-type/{id}/", "get", { path: { id: partTypeId } });
      if (data.value) {
        if (!stores.partTypeStore[partTypeId]) {
          stores.partTypeStore[partTypeId] = {};
        }
        stores.partTypeStore[partTypeId].part_type = data.value;
      }
      if (error.value) {
        notifyTooltipError();
      }
      loading.partTypeStore = false;
    }

    if (stores.partTypeStore[partTypeId] && !stores.partTypeStore[partTypeId].circuits) {
      loading.partTypeStore = true;
      const { data, error } = await call("/api/department/tooltip/part_type_circuit/{id}/", "get", { path: { id: partTypeId } });
      if (data.value) {
        stores.partTypeStore[partTypeId].circuits = data.value;
      }
      if (error.value) {
        notifyTooltipError();
      }
      loading.partTypeStore = false;
    }

    return stores.partTypeStore[partTypeId];
  };

  const getInvoiceTooltipData = async (invoiceId: string) => {
    return await getSimpleData("invoiceStore",
      `/api/department/tooltip/invoice/${invoiceId}`,
      invoiceId) as TooltipInvoice;
  };

  const getParameterTooltipData = async (parameterId: string) => {
    return await getSimpleData("parameterStore",
      `/api/department/tooltip/parameter-history/${parameterId}`,
      parameterId) as ParameterTooltip;
  };

  return {
    resetStore,
    getLocationTooltipData,
    getDeviceTooltipData,
    getAddressTooltipData,
    getCenterTooltipData,
    getEventTooltipData,
    getOfferTooltipData,
    getPartnerTooltipData,
    getPartTypeTooltipData,
    getInvoiceTooltipData,
    getPartTooltipData,
    getDocumentationTooltipData,
    getParameterTooltipData,
    getBusinessCaseTooltipData
  };
});
