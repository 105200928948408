import { cloneDeep, mapValues, merge, pickBy } from "lodash-es";

type Params = Record<string, any>;
type CallParams = {
  method?: APIPathMethod;
  params?: Params;
  body?: Params;
  opts?: Params;
};
export type StoreType<T> = {
  [shelf: string]: T
};
interface FDCOptions {
  shelf?: string;
  paginationLimit?: number;
  noLoad?: boolean;
  cache?: "subsequent" | "persistNavigation";
}

const LOADING_KEY = "_loading";

function getShelf (method?: string, filters?: Params, body?: Params) {
  const filterStr = filters ? JSON.stringify(filters) : "_blank";
  const bodyStr = body ? JSON.stringify(body) : "_blank";
  return `${method}_${filterStr}_${bodyStr}`;
}

function areParamsValid (params?: Params | null) {
  if (!params) {
    return true;
  }
  return Object.values(params).every(val => val !== undefined);
}

export function useFormDataController () {
  const { call, cachedCall } = useApi();
  const { clearArrayOrObject } = useUtils();

  function createDataController <T extends object> (store: StoreType<T>, url: APIPath, defaultVal: any = []) {
    if (store[LOADING_KEY] === undefined) {
      store[LOADING_KEY] = {} as T;
    }

    /**
     * Always fetches a new list from the API, regardless of cache.
     *
     * Updates the cache. The cache is per-component by default, use `cache: "persistNavigation"` in function `options` arg to use Nuxt fetch caching.
     * @returns an array of list items (a reactive proxy).
     */
    const load = async ({ method = "get" as APIPathMethod, params, body, opts }: CallParams = {}, options: FDCOptions = {}) => {
      const where = options.shelf || getShelf(method, params, body);
      if ((store[LOADING_KEY] as Record<string, boolean>)[where]) {
        return store[where];
      }

      if (store[where] === undefined) {
        store[where] = reactive(cloneDeep(defaultVal));
      }

      if (!areParamsValid(params) || !areParamsValid(body)) {
        return undefined;
      }

      (store[LOADING_KEY] as Record<string, boolean>)[where] = true;
      const callFn = options.cache === "persistNavigation" ? cachedCall : call;
      const internalOpts = merge(options.cache === undefined ? { cache: "no-cache" } : {}, opts);
      const { data, error } = await callFn(url, method, { query: { page_size: options.paginationLimit, ...params } }, body, internalOpts);
      if (data.value) {
        clearArrayOrObject(store[where]);
        Object.assign(store[where], (data.value as any)?.results ? (data.value as PaginatedResponse<object>).results : data.value);
      }
      if (error.value) {
        useNotifier().error("load");
      }
      (store[LOADING_KEY] as Record<string, boolean>)[where] = false;

      return store[where];
    };

    /**
     * Gets the items from cache if a record exists. Otherwise **calls (!)** `load()`.
     *
     * (!) You **cannot** await this function, you will want to use `getAsync()` in most cases.
     *
     * @returns an array of list items (a reactive proxy).
     */
    const get = ({ method = "get" as APIPathMethod, params, body, opts }: CallParams = {}, options: FDCOptions = {}) => {
      const where = options.shelf || getShelf(method, params, body);
      if (!options.noLoad && (!store[where] || !Object.keys(store[where]).length) && !(store[LOADING_KEY] as Record<string, boolean>)[where]) {
        load({ method, params, body, opts }, options);
      }
      return store[where];
    };

    /**
     * Gets the items from cache if a record exists. Otherwise awaits `load()`.
     *
     * @returns an array of list items (a reactive proxy).
     */
    const getAsync = async ({ method = "get" as APIPathMethod, params, body, opts }: CallParams = {}, options: FDCOptions = {}) => {
      const where = options.shelf || getShelf(method, params, body);
      if (!options.noLoad && (!store[where] || !Object.keys(store[where]).length) && !(store[LOADING_KEY] as Record<string, boolean>)[where]) {
        await load({ method, params, body, opts }, options);
      }
      return store[where];
    };

    /**
     * Get the controller url with query parameters, which will be used exactly as they are given in queryset.filter() statement in API.
     * @param queryParams \{name: value} object, names support Django filter syntax, e.g. 'some_field_name__isnull'
     * @param emptyUrlIfAllParamsNullish if true, returns empty string if all params are null or undefined; default false
     * @param includeNullishValues - if true, includes null and undefined values in the query string; default false
     * @returns url with query parameters
     */
    const queryUrl = (queryParams: Params, emptyUrlIfAllParamsNullish = false, includeNullishValues = false) => {
      if (emptyUrlIfAllParamsNullish || !includeNullishValues) {
        queryParams = pickBy(queryParams, val => val !== null && val !== undefined);
      }
      if (emptyUrlIfAllParamsNullish && Object.keys(queryParams).length === 0) {
        return "";
      }
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");

      return queryString ? `${url}?${queryString}` : url;
    };

    /**
     * Same as queryUrl(), but queryParams values can be of type MaybeRefOrGetter\<any>.
     *
     * This is especially useful for reactive form data, as a getter pointing at a proxied field enables the query to update when the proxy updates.
     *
     * You will probably want to use the returned value in a reactive/computed object or straight in template.
     *
     * @returns computed url with query parameters (ComputedRef\<string>).
     */
    const queryUrlReactive = (queryParams: Params, emptyUrlIfAllParamsNullish = false, includeNullishValues = false) => {
      const comp = computed(() => {
        const converted = mapValues(queryParams, val => toValue(val));
        return queryUrl(converted, emptyUrlIfAllParamsNullish, includeNullishValues);
      });
      return comp;
    };

    return { load, get, getAsync, url, queryUrl, queryUrlReactive };
  }

  return { createDataController };
}
