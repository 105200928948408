<template>
  <NuxtLoadingIndicator />
  <n-config-provider>
    <n-loading-bar-provider>
      <n-message-provider>
        <n-notification-provider>
          <n-modal-provider>
            <n-dialog-provider>
              <NuxtLayout>
                <NuxtPage />
              </NuxtLayout>
            </n-dialog-provider>
          </n-modal-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import "@olc/vue-loader/dist/style.css";
import "@vuepic/vue-datepicker/dist/main.css";
import { NConfigProvider, NLoadingBarProvider, NMessageProvider, NNotificationProvider, NModalProvider, NDialogProvider } from "naive-ui";

useHead({
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" }
  ]
});
</script>
